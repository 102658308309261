let soul = [
'你昨天晚上又没回我信息，我却看见你的游戏在线，在我再一次孜孜不倦的骚扰你的情况下，你终于跟我说了一句最长的话“**你他妈是不是有病**”，我又陷入了沉思，这一定有什么含义，我想了很久，你竟然提到了我的妈妈，原来你已经想得那么长远了，想和我结婚见我的父母，我太感动了，真的。那你现在在干嘛，我好想你，我妈妈说她也很喜欢你。',
'今天我观战了一天你和别人打游戏，**你们玩的很开心**；我给你发了200多条消息，你说没流量就不回；晚上发说说没有人爱你，我连滚带爬评论了句有“我在”，你把我拉黑了，我给你打电话也无人接听。对不起，我不该打扰你，我求求你再给我一次当好友的机会吧！',
'我爸说再敢网恋就打断我的腿，幸好不是胳膊，这样我还能继续**和你打字聊天**，就算连胳膊也打断了，我的心里也会有你位置。',
'你说你情侣头像是一个人用的，空间上锁是因为你不喜欢玩空间，情侣空间是和闺蜜开的，找你连麦时你说你在忙工作，每次聊天你都说在忙，你真是一个**上进的好女孩**，你真好，我好喜欢你！',
'你跟他已经醒了吧？我今天捡垃圾挣了一百多，明天给你打过去。你快点休息吧，我明天叫你起床，给你点外卖买烟，给你点你最喜欢的奶茶。晚上我会继续去摆地摊的，你不用担心我，你床只有那么大睡不下三个。**你要好好照顾好自己，不要让他抢你被子**。我永远爱你！',
'她三天没回我的消息了，在我孜孜不倦地骚扰下她终于舍得回我“**nmsl**”，我想这一定是有什么含义吧，噢！我恍然大悟原来是**尼美舒利颗粒**，她知道我有关节炎让我吃尼美舒利颗粒，她还是关心我的，但是又不想显现的那么热情。天啊！她好高冷，我好像更喜欢她了！',
'你想我了吧？可以回我消息了吗？我买了万通筋骨贴，你**运动一个晚上腰很疼**吧？今晚早点回家，我炖了排骨汤，我永远在家等你。',
'昨晚你和朋友打了一晚上游戏，你破天荒的给我看了战绩，虽然我看不懂但是我相信你一定是最厉害的、最棒的。我给你发了好多消息夸你，告诉你我多崇拜你，你回了我一句“**啥B**”，我翻来覆去思考这是什么意思，Sha[傻]，噢你是说我傻，那B就是Baby的意思了吧，原来你是在叫我**傻宝**，这么宠溺的语气，我竟一时不敢相信，其实你也是喜欢我的对吧。',
'今天我还是照常给你发消息，汇报日常工作，你终于回了我四个字：“**嗯嗯，好的。**”。你开始愿意敷衍我了，我太感动了，受宠若惊。我愿意天天给你发消息，就算你天天骂我，我也不觉得烦。',
'你昨天晚上又没回我的消息，在我孜孜不倦的骚扰下，你终于舍得回我了，你说“**滚**”，这其中一定有什么含义，我想了很久，滚是三点水，这代表你对我的思念也如**滚滚流水**一样汹涌，我感动哭了，不知道你现在在干嘛，我很想你。',
'听说你想要一套化妆品，我算了算，明天我去工地上**搬一天砖**，就可以拿到200块钱，再加上我上个月攒下来的零花钱，刚好给你买一套迪奥。',
'今天表白被拒绝了，她对我说能不能脱下裤子**撒泡尿照照自己**。当我脱下裤子，她咽了口水，说我们可以试一下。',
'刚从派出所出来，原因前几天14号情人节，我想送你礼物，我去偷东西的时候被抓了。我本来想反抗，警察说了一句老实点别动，我立刻就放弃了反抗，因为我记得你说过，你喜欢**老实人**。',
'疫情不能出门，现在是早上八点，你肯定饿了吧。我早起做好了早餐来到你小区，保安大哥不让进。我给你打了三个电话你终于接了“**有病啊，我还睡觉呢，你小区门口等着吧**”。啊，我高兴坏了！你终于愿意吃我做的早餐了，还让我等你，啊！啊！啊！好幸福噢！',
'我存了两个月钱，给你买了一双**北卡蓝**，你对我说一句“谢谢”，我好开心。这是你第一次对我说两个字，以前你都只对我说滚。今天晚上逛**闲鱼**，看到了你把我送你的北卡蓝发布上去了。我想你一定是在考验我，再次送给你，给你一个惊喜，我爱你。',
'昨天**你领完红包就把我删了**，我陷入久久地沉思。我想这其中一定有什么含义，原来你是在欲擒故纵，嫌我不够爱你。无理取闹的你变得更加可爱了，我会坚守我对你的爱的。你放心好啦！今天发工资了，发了1850，给你微信转了520，支付宝1314，还剩下16。给你发了很多消息你没回。剩下16块我在小卖部买了你爱吃的老坛酸菜牛肉面，给你寄过去了。希望你保护好食欲，我去上班了爱你~~',
'在保安亭内看完了最新一集的梨泰院，曾经多么倔强的朴世路因为伊瑞给张大熙跪下了，亭外的树也许感受到了**我的悲伤**，枯了。我连树都保护不了，怎么保护你，或许保安才是真的需要被保护的吧。我难受，我想你。over',
'难以言喻的下午。说不想你是假的，说爱你是真的。昨天他们骂**我是你的舔狗**，我不相信，因为我知道你肯定也是爱我的，你一定是在考验我对你的感情，只要我坚持下去你一定会被我的真诚所打动，加油！不过我要批评你一下，昨晚你说**去酒店跟人斗地主**，我寻思两个人也玩不了呀。算了，不想了，毕竟打牌是赌博行为，不太好。',
'明天就周六了我知道你不上班，但是我怕你睡懒觉不吃早饭饿坏自己。我早晨4点去菜市场买了新鲜活鸡**给你炖鸡汤**，阿姨给我用箱子装了起来，我骑上我280买的电动车哼着小调回家，心想你一定会被我感动的，箱子半路开了，鸡跑了，拐到了一个胡同里，凌晨4点的胡同还有穿超短裙和大叔聊天的美女，不禁感叹这个世界变了，她问我找什么，…………。对不起，我爱你',
'12点队长过来准时交班，出去的车辆按喇叭我也没听到，只因我在监控中看到了穿睡衣出来倒垃圾的你，**望你望的入神**不由的傻笑了起来，队长过来骂我扣了我一天工资。我委屈，想抱你。你送的泡面真好吃。',
'今天的我排位输了好多把，我将这些事情分享给你，但是你一个字都没有讲，我在想你是不是在忙？我头痛欲裂，终于在我给你发了几十条消息之后，你回了我一个“**脑子是不是有病？**”，原来你还是关心我的，看到这句话，我的脑子一下就不疼了，今天也是爱你的一天。',
'我存了半年的工资，给你买了一只LV，你对我说了一句“**你真好**”，我好开心，这是你第一次这么认可我，以前你都只对我说滚。今天晚上逛闲鱼，看到你把我送你的LV发布上去了。我想，你一定是在考验我，于是我用借呗里的钱把它买了下来，再次送给你，给你一个惊喜，我爱你。',
'其实我每月工资6000，但我只给你转2000，你以为我给你了全部。才不是，我一共舔了3个啦，**我要舔的雨露均沾**，才不会把你当成唯一。',
'昨天你把我拉黑了，我看着红色感叹号陷入了久久的沉思，我想这其中一定有什么含义？红色红色？我明白了！红色代表热情，你对我很热情，你想和我结婚，我愿意。',
'今天你问我借了两千块钱，说要做个手术，你果然还是爱我的，**不是我的孩子，你不要**。 ',
'中午你无故扇了我一巴掌，我握着你的手说“手怎么这么凉，都怪我没有照顾好你，一定要更加对你好”。',
'我给你打了几通电话，你终于接了。听到了**你发出啊啊啊啊的声音**，你说你肚子痛，我想你一定是很难受吧。电话还有个男的对你说“来换个姿势”，一定是**在做理疗**了。期待你早日康复，我好担心。',
'昨天晚上好冷，本来以为街上没人，结果刚刚**偷电动车**的时候被抓了，本来想反抗，但警察说了一句老实点别动，我立刻就放弃了抵抗，因为我记得你说过，你喜欢**老实人**。',
'找你连麦时你说你在忙工作，每次聊天你都说在忙，你真是一个**上进的好女孩**，你真好，发现我越来越喜欢这样优秀的你。',
'你从来没说过爱我，聊天记录搜索了一下“爱”，唯一的一条是：**你好像乡村爱情里的刘能啊**。',
'今天好开心啊，和你一起在峡谷嬉戏，打完一波团战之后看到你在打大龙，残血的我跳过去直接被龙爪拍死，但这一刻我觉得好浪漫，**死在你的脚旁边，这是我离你最近的一次**。',
'哥们，求你和她说句话吧，这样她就不会那么难过了。',
'今天你把我的微信拉黑了，这下我终于解放了！以前我总担心太多消息会打扰你，现在我终于不用顾忌，不管我怎么给你发消息，都不会让你不开心了。等我**攒够5201314条**我就拿给你看，你一定会震惊得说不出话然后哭着说会爱我一辈子。哈哈。',
'昨天你把我删了，我陷入了久久的沉思 。我想这其中一定有什么含义，你应该是欲擒故纵吧，嫌我不够爱你。突然觉得**无理取闹的你变得更加可爱**了，我会坚守我对你的爱的 你放心好啦！这么一想，突然对我俩的未来更有期望了呢。',
'今天上班不是太忙，百无聊赖，又翻出了你的相片，看了又看。今天是我认识你的第302天，也是我爱你的第302天，可是这些你并不知道，也许**你知道了，也不会在意**吧。 此刻的我好想你！ ',
'今天你跟我说我很丑，让我不要骚扰你了。我听了很高兴，小说里的主角都像你这样，最开始表现的很厌恶，但最后**总会被我的真心打动**。你现在有多讨厌我，以后就会有多爱我。嘻嘻。',
'我坐在窗边给你发了99条消息，你终于肯回我了，你说“**发你妈啊**”，我一下子就哭了。原来努力真的有用，你已经开始考虑想见我的妈妈了，你也是挺喜欢我的。',
'刚才我找你说话，你回了一个滚，我陷入了沉思，你还是如此的关心我，知道我腿受伤了，让我这样走，好感动！看来你还是爱我的！',
'今天下雨了，我去你公司接你下班。看见我你不耐烦的说“**烦不烦啊，不要再找我了**”，一头冲进雨里就跑开了。我心里真高兴啊，你宁愿自己淋雨，都不愿让我也淋湿一点，你果然还是爱我的。',
'晚上和你聊天，10点钟不到，你就说“**困了，去睡觉了**”。现在凌晨1点钟，看到你给他的朋友圈点赞评论，约他明天去吃火锅，一定是你微信被盗了吧。',
'今天我主动给你发了游戏邀请，邀请你和我单挑安琪拉，虽然我安琪拉很菜，可是为了和你打游戏，我还是毅然决然给你发了邀请。你说你不接受，你在打其他游戏。联想到我自己很菜，我突然明白，原来你还是在乎我的，只是不想一遍遍连招一套的在泉水送我走。我再一次感动哭了，因此，我好像更喜欢你了，你可真是一个宝藏男孩！',
'你的头像是一个女孩子左手边牵着一条秋田犬，犬=狗，而**我是一条舔狗**。是不是代表你的小手在牵着我呢？',
'今天发工资了，我一个月工资3000，你猜我会给你多少，是不是觉得我会给你2500，自己留500吃饭？你想多了，我3000都给你，因为厂里包吃包住。',
'昨天就为你充了710点卷，虽然知道你不会玩不知去向，但你说好看，你刚才说小号想要还想要一个，爱你的我还是满心欢喜的把剩下的100元伙食费又给你充了710，然后看到你小号并没有买，而是你送给了你的一个弟弟，你对弟弟真好，好有爱心，我感觉对你陷得很深了。',
'今天我给你发消息，你回复我“**nmsl**”，我想了半天才知道你是在夸我，原来是**你美死了**，你嘴真甜，我爱你。',
'你说你想买口红，今天我去了叔叔的口罩厂做了一天的打包。拿到了两百块钱，加上我这几天**省下的钱刚好能给你买一根小金条**。即没有给我自己剩下一分钱，但你不用担心，因为厂里包吃包住。对了打包的时候，满脑子都是你，想着你哪天突然就接受我的橄榄枝了呢。而且今天我很棒呢，主管表扬我很能干，其实也有你的功劳啦，是你给了我无穷的力量。今天我比昨天多想你一点，比明天少想你一点。',
'在我一如既往的每天跟她问早安的时候，她今天终于回我了。我激动地问她我是不是今天第一个跟她说话的人，她说不是，是**她男朋友把她叫起来退房**的。',
'听说你朋友说今天出门了，我打扮成精神小伙来找你，没想到你竟然对我说“**给我爬，别过来**”我当场就哭了，原来真心真的会感动人，你一定是知道，穿豆豆鞋走路脚会很累，让我爬是因为这样不会累着脚，其实你是喜欢我的吧',
'今天把你的备注改成了「**对方正在输入...**」，这样我就知道你不是不想回我，刚又给你发了消息，看到你在思考怎么回我，我就知道你和我一样，心里有我。',
'今天在楼上窗户上看见你和他在公园里接吻，我看见哭了出来，并打电话给你，想问问你为什么？但你说怎么了，声音是那么好听。于是我说“**以后你和他接吻的时候，能不能用我送给你的口红啊？**”',
'我退了无关紧要的群，唯独这个群我没有退，因为这里有一个对我来说很特别的女孩子，我们不是好友，**我每天只能通过群名片看看她**，虽然一张照片也看不到，我也知足了，我不敢说她的名字，但我知道她是群里面最美的女孩子，她说我们这样会距离产生美~ 我想想发现她说的挺对的，我心里很开心。',
'今天早上我告诉你我想你了，你没理我。今天中午我给你打电话，你不接，打第二个你就关机。晚上我在你公司楼下等你，你对我说的第一句话就是滚“**滚，别烦我，别浪费时间了**”，我真的好感动，你居然为我考虑了，怕我浪费时间。呜呜呜，这是我爱你的第74天。',
'我坐在窗边给你发了99条消息，你终于肯回我了你说“**发你妈啊**”，我一下子就哭了，原来努力真的有用，你已经开始考虑想见我的妈妈了，你其实也是挺喜欢我的。',
'你一个小时没回我的消息，在我孜孜不倦地骚扰下你终于舍得回我了“**在做爱**”，这其中一定有什么含义，我想了很久，“在做爱”这简简单单的三个字肯定是三句话，分别是**我在忙、做你女朋友、我爱你**，想到这里我不禁流下了眼泪，我这么长时间的喜欢没有白费，不知道你现在忙干嘛，但我很想你。',
'最近我暗恋的女生每天都和不同的男生约会，我想总有一天会轮到我，我问她什么时候能见见我？她说**下辈子吧**。她真好，下辈子还要和我在一起。',
'你好像从来没有对我说过晚安，我在我们的聊天记录里搜索了关键字：“晚安”，你说过一次：**我早晚安排人弄死你**。',
'你Hellow忘闭麦，我听见你跟你爸爸在打架，啪啪啪的一直打的可凶了，你哭唧唧的说“**爸爸，不要，不……**”。听见你被他打，我很着急，听着你央求着他喊“啊…啊…啊”，我很伤心，就这样听着你被打了好久，你竟然开始赌气的嘴硬的喊着“**再大力点，弄死我！**”，你爸太不是人了，你这样喊他真的会虐待死你的！果然，你突然哭着喊着“求你别……不要……”，我突然紧张起来！他竟然要拿枪打死你！！我赶忙拿起手机拨打110。电话刚拨打出去，你那边传来了啊啊啊啊凄惨的喊叫声之后，你Hello掉了，只剩下我听到警察叔叔“喂？喂？这里是110报警电……”，我握紧拳头、咬牙切齿，发誓一定要杀了你爸，为你报仇！',
'今天你依旧高冷，给你发了好多消息，都没回。可你越是这样，我越是喜欢你。在我不断的嘘寒问暖下，你终于不再矜持了，你回了一个**SB**。在我查遍了英语词典后，我终于明白了这两个字母的意思。S是Sweet、B是Baby，那么你一定是在叫我**Sweet Baby**了，我很感动。',
'我打游戏太菜了，偶尔跌跌撞撞摘了几颗星星，总是又掉下来，**我守不住上一把摘掉的星星，也守不住你。**',
'今天天气有点冷，想偷你的心却还是没有成功，在床上的我现在的心情就像天气预报，说**明天有雨，我都听成明天有你。**',
'你终于喊我双排了，让我拿我胜率最高的英雄。我一般都不玩的，因为胜率太高了怕掉，可是为了你我还是选了，你好像很**开心给我发了句“fw”**，我懂了，你是想夸夸我说我法王，你真好！我越来越喜欢你了。',
'你说我再敢纠缠你就打断我的腿，幸好不是胳膊，这样我还能继续下单给你买奶茶，就算连胳膊也打断了，我的心里还会有你位置。',
'今天你约我去陪你买衣服，尽管我知道只是因为我的身形像你异地的男朋友，比较好试衣服而已。买完衣服，寄快递**写他名字的时候，看见你嘴角翘起幸福的笑**来。那一刻，我多希望我可以也叫那个名字，哪怕只有一分钟！！',
'天气很暖和，但是风有点大，坐在保安亭内看见了你挽着一个陌生男人出了门，看起来很亲密，**你问我附近哪里有毓婷卖**，我指给你看了，好开心，你跟我说话了。',
'我可以再见你一面吗，我可以站远一点，不让你同事发现我。',
'今天的彩虹桥格外的迷人，可是当你站在彩虹桥下，它便暗淡了许多，原来你才是最迷人的。',
'给你发了几千条消息，你终于把我拉黑了。我好开心，那是你对我的唯一回应。我哭了好久，终于等到你的回应了，你放心，我会继续爱你。',
'今天没怎么和你说话，我找了半个小时的文案，发了条仅你可见的朋友圈，你却来一句“偷了”。',
'我暗恋的人说眼睛疼，所以我买了瓶眼药水寄过去，但她却告诉我她有喜欢的人了，让我别再打扰。距离遥远顺丰都要两天才能到，可她为什么只用了一秒就把眼药水滴进了我眼睛里。',
'雷阵雨打雷了，我担心你害怕打雷声，就一早跑到你家楼下，可能是心灵的呼唤使你打开了窗户，那一刻我感觉我是世界少最幸福的人，你打开窗户对我喊：“**惊雷，这通天修为天塌地陷紫晶锤**”，虽然我不知道是什么意思，我就当作你向我表白吧！',
'今天降温了，风吹的我很冷，我站在树下点了一根烟，我抽了三分一，风抽了三分之二，我把自己的心关起来锁在了一个很深的地方。**我是一个不称职的电焊工，我电不到你，也焊不牢你的心。**',
'你扇了我一巴掌，我握着你的手说“**怎么这么凉**”',
'她好像从来没有主动找过我，我看了一下我们的聊天记录，只有情人节这天给我发的入住酒店叫我帮她砍一刀。',
'你跟他打完游戏了吧，也不知道他有没有在游戏里凶你。如果你不高兴了一定要告诉我哦，我会一直陪伴你的。今天厂长看我表现好，奖了我一百块钱奖金，我现在就给你打过去，给你买小乔的青蛇皮肤，别人有的你也会有。',
'今早你又拉黑了我的微信，我很难过，还好我还有小号可以继续舔你，你没想到吧，你总得意的觉得自己有很多舔狗，不少我一个，但其实都只是我一个人而已。',
'我的嘴真笨，总能把天聊死了！跟你找话题好难，何况我又这么喜欢你，连发个表情包都要挑拣半天呢！我最近开始期待夜晚了，其实我在说：“**今天我也很喜欢你，也想你了！**”',
'你还有钱吗？没有我给你打点。',
'你没再来找我了，我发了一条仅你可见也没任何回应，**你的朋友圈每一条我都点了赞，也没引起你的注意**，我不知道你有没有再想起我，是不是我等的还不够久。',
'看到**你和一个帅哥吃饭，看起来关系很亲密的样子**，你从来没有告诉我你还有这么好的朋友，一定是怕我多想，你总是为我着想，你对我真好。',
'今天一大早就去帮她海底捞排队，**她男朋友想吃海底捞**，我要是去晚了的话排不上队，她男朋友吃不上海底捞的话又该骂她，我怕她扛不住，她男朋友骂人很凶的.',
'昨晚你终于回我信息了，你回了一句谢谢还加了一个爱心。当时我在工地上激动的差点把隔壁的吊塔阿姨给亲了。不过我想了想你笑起来的样子我还是忍住了。你给我发爱心，一定是已经爱上我了吧，放心，我连咱们的孩子名字都想好了。等我，我一定会继续努力挣钱，给你买更多的化妆品，发更多的红包！',
'想了很久终于想通了，你说孩子不是我的。没关系的宝贝，我愿意跟着你孩子姓。',
'在我不懈努力下，你终于回了我三个字 “**你滚啊**”，之后出现了红色的感叹号。我知道农村网络不好消息发不出去了，但是我还是坚持每天一个99+。我不觉得卑微，反而很开心。虽然你每次都会叫我滚开，但是我知道你是在欲擒故纵。',
'今天打单子赚了56，给你转了52自己留了4块钱。我花两块买了两包泡面，用剩下的两块钱买了一瓶矿泉水，自己烧水泡面吃，而你用那52块钱**想都没想的给你别的哥哥买了皮肤**。 我太开心了，因为你用上我的钱了，以后我要赚更多的钱给你',
'今天你给了我一拳，因为我在你回宿舍的路上叫了一声**Honey**，你不顾周围的劝阻也想揍我，虽然最后失败了，但我还是看到了你为我对抗世界的决心和勇气！',
'从我起床到晚上一直在给你分享我的生活碎片并关心你的衣食住行，直到深夜你终于回我了“**累了，睡了。**”，原来你是爱我的，这么晚了还在担心我累并嘱咐我早点睡。',
'今天我看见她好像特别难受，不知道为什么，我好想安慰一下，可是当我问的那一下，**她叫我滚开**。或许我真的有打扰到她吧，我想让她开心，所以我走开了',
'我发了条朋友圈，她去点赞了，我高兴的把她截屏并发给她，问他：你是不是有啥想对我说的？她下一秒把点赞取消，告诉我“你看我没点赞”。',
'宝宝，刚刚睡醒没看到你回我的消息，昨天睡觉梦到你换了情头，吓醒才意识过来是做梦 ，所以你为什么不回我的消息呢？这次不回我我下次要找什么理由再去找你呢？我委屈，我好想你。',
'我生怕打扰到你周末早睡，然后非常纠结的给你发了“**早上好宝宝，在干嘛?**”的信息。你开心地回复到:“**正和男朋友腻在一起呢，他在喂我吃早餐，嘻嘻嘻**”。看你这么开心，心里也替你高兴。',
'你半天没理我了，我忍不住给你打了好几个电话，你终于接通了，跟我说“**草**”。我觉得这肯定不是字面意思，我考虑的很久，**草的下面是早，代表着你对我的爱犹如旭日东升的太阳绵绵不断。**我不禁忍不住，流下了感动的泪水，我知道你肯定也喜欢我的吧！',
'现在已经凌晨一点多了，我望着手机屏幕迟迟没有他的消息：你知道吗？我等了一晚上你的消息。他终于回复我了：是我让你等的？',
'今天我鼓起勇气问她是喜欢狼狗还是喜欢奶狗，她说她喜欢狼狗，我问她觉得我是哪一种，**她说我是土狗。**',
'今天发工资了，发了2000，给你微信转了520 支付宝1314，还剩下166。中午给你发了很多消息你没回，总是正在通话中。**你让我别烦，别打扰你和你的宝贝连麦**，好吧没关系，宝宝我爱你，所以我不生气，剩下166块我在网上买了你爱吃的零食，还有一盒咽喉片给你寄过去了，希望你保护好嗓子，我爱你。',
'今天保安队长心情不好拿我撒气，因为他不会唱惊雷，他的女神很生气的骂他说他不懂潮流不懂时尚，所以队长冲我吼了一天惊雷，这通天修为天塌地陷紫金锤我委屈，我想你。',
'每次我发了好几行的文字，你只回复了**嗯，哦，啊，好的**。我太感动了，无论我说什么你总这样对我百依百顺的，我怎么会有其他的要求呢。尤其每个夜晚，我说晚安，宝贝，总是等不到没有回复的晚安，原来你就这样让我彻夜难眠想你，欲擒故纵这招高明，一直拴住我的心，让我无法摆脱你，我离不开你的。',
'今天我还是日常给你发早安午安问你吃了没，你想吃什么，你说了句sb，我特别开心你回我一个字以上的消息了，sb一定是随便的意思吧，我喜欢的人就是这样不挑食，我感觉你更完美了，今天我也在非常喜欢你。',
'今早睡觉前一直幻想起床后能收到你的“早安”，醒后打开手机，聊天框一片空白，我按耐不住想你的心情，激动的打出一个“早”。**你说我昼伏夜出，是猪。**猪是多么可爱的生物啊~你一定是在夸我吧。我愿意！我愿意做你的猪宝宝。',
'这是我用我奶奶的手机给你发的信息，不要再拉黑我了，我真的没有手机联系你了。',
'发了1839，给你微信转了520 ，支付宝1314 ，还剩下5。 给你发了很多消息你没回，剩下5块我在小卖部买了你爱吃的老坛酸菜牛肉面，给你寄过去了。希望你保护好食欲，我去上班了，爱你～',
'今天你终于主动找我了，**你甩给了我一个淘宝链接让我帮你付款**，这是你让我给你买的第一个东西，我付了款你对我说“**谢谢**”，这让我感觉我们的关系更进一步了，真的是越来越爱你了。',
'昨天给你发了99条约你一起植树的消息，今天你终于肯回我了， 你说“**你先去植发吧，死秃子。**” 我一下子就哭了 ，原来努力真的有用 ，你已经开始关心我了，你也是挺喜欢我的吧。',
'我不知道怎么跟你相处，你有游戏要打、有小说要看、有觉要睡，朋友喊了还要去。而**我的生活只有想你想你想你**，我怎么配得上跟你谈恋爱呢，我只是一个得不到爱情的小笨蛋罢了。',
'你知道晚安的拼音吗，是wanan哦，也就是我爱你爱你。嘻嘻，每次我给你发这两个字你都猜不到吧，**也许是我信号不好，给你发的消息都带有红色感叹号**，这一点也是你爱我的表现吧。和他同居的日子要好好的哦，不要妨碍到别人了，我上次打电话给你，听你哭的好惨，一直喊不要，我当时真的气炸了，他怎么可以这样对你。你一个女孩子在外面要坚强啊，外面真冷，要记得保暖哦，我会继续赚钱的。',
'你好像成熟了，你学会隐忍，开始压抑自己对我的感情。这很好……可是我觉得自己被你忽略了……**你好像看不见我。**这不可能，对吗？',
'她刚刚说去洗澡了，我等了她3个小时。我问她玩不玩，她说要睡觉了。可当我上线看了她的战绩，原来已经有别的璇璇了。没关系，明天我也会舔你。',
'今天我对她“我问你哦，爱奇艺会员，你有吗？” 他没发现是我爱你的藏头诗，还叫我穷鬼，让我滚。我看不了青春有你，我的青春也没有你',
'今天你说了要和我打电话，我等了一天，马上十二点了才打过来，我有点不高兴就挂了，**你骂了句给脸不要脸**。我想了一下，哎呀你还会关心我的脸，多么善良的女孩子，我发誓还能再等一天电话',
'今天有点口腔溃疡，不太想舔了，和你的旧爱好好的啊，不要不开心了。',
'今天女神给我发信息问我有没有腾讯视频会员，我说没有，她说好吧。为了维护在她心中的形象，我马上充值了一个月的会员，我把账号发过去，我能想象到她对我的肯定，我离女神的距离越来越近了',
'听网上说今天的月亮最大最亮，我说我想和你一起看月亮，你却回我**看你妈**，我听你的话看了我妈一晚上。',
'自从你把我的微信删除了之后，我经常去你宿舍楼下等你，早上偶尔去，晚上一定在。平时你都故意不看我，因为你比较害羞腼腆，但今天你终于忍不住对我的在乎，把我叫到小树林里独处。你说了什么我忘了，好像是让我不要老是找你免得你把持不住吧。嘻嘻，你真可爱。',
'我还是很喜欢你，就像，我如果有一百块钱，我愿意花30打车去找你，然后花60去找狗胖子买两张惊奇队长，然后花八块钱给你买一杯冰阔乐。看完电影，我会用最后两块钱，去坐公交，去银行取两万自己去吃螃蟹龙虾三文鱼蛋糕松塔章鱼丸子酸菜鱼香辣鸡翅麦旋风芒果布丁金丝面羊肉串火锅。',
'今天我的女神终于跟我提“亲嘴”这两个字啦，她**让我给她去买点亲嘴烧送过去，她老公想吃。**',
'今天你破天荒的给我发了个早，我开心极了，难道这就是恋爱的感觉吗。我一看时间，十二点整，你一醒来就在想我，我流下了激动的眼泪。又想到你到现在都没有吃饭，我给你发了两百块钱红包。**你快速的领取了，却迟迟没有回我。**我想你可能也沉浸感动当中吧，我给你发了句去吃点东西吧。回复我的却是一个红色感叹号，红色代表爱情，你一定是不好意思说出口，才用这么委婉的方式表达你对我的爱，我也爱你。',
'昨天给你发了晚安，你没有回我，我知道你是害怕打扰我休息，于是就在朋友圈群发的晚安。我很开心。',
'今天给她发了十一条消息，她还是没有回我，我知道她在跟男生聊天，但我就是想用消息的数量刷一下存在感 。我做不了你微信聊天的主人公那我只能做你和他聊天的背景提示音。',
'天气很暖和，但是风有点大，在小区绿化区搬砖的时候眼睛不小心进了沙子，我委屈、我想你、想让你给我呼呼。',
'你和他在做爱吧，啪完盖好被子别着凉。今天搬砖赚300我给你转过去了，我睡啦，继续努力赚钱给你。可以的话多回我几句，今天你回了我三句“**在做爱、好爽、别烦**”比昨天多了一句好爽，我好开心。爱你！',
'今天一个女孩子对我表白说喜欢我，可是我的心里只有你啊，我立马拒绝了她，她说她不会放弃的，我的心里只有你一个人啊，不会答应她的。',
'话说今天发工资了，五千，你猜猜看我会给你多少？四千多？不对，我把我的卡给你，密码你的生日。',
'早上你和我说你腰酸死了，昨天晚上累坏了吧，我还是会给你做好吃的，果然你什么时候都是好看的。',
'你今天**让我交钱的时候发的字是最多的**，我想你一定更爱我了，都粘着我了，我一定努力赚钱的。',
'我发消息问你在干嘛，你没回我消息。切到游戏客户端，发现你已经开局15分钟了，我觉得那等着你玩完这一局就好了。结果我就看着你一直在组队开局，又组队开局。没什么 就是想告诉你要少玩手机，**不想理我就不用理我，没关系的。**',
'你现在在干嘛？吃饭了嘛？想我了嘛？你已经5小时没理我了，我一直在等你。宝贝你知道吗，我刚下楼倒垃圾时看到门口保安写的日记，看到他说"他委屈他想她"的时候我笑了出来，哈哈哈他真的好像一条舔狗啊，还好我不是，嘿嘿嘿嘿嘿嘿！他真可怜。',
'**我翻你倒的垃圾，被我找到了一个避孕套。**我知道，他来过，我赶紧把套套拿出来泡水喝，我知道，里面有你的味道。',
'我在你家楼下垃圾桶**翻出来了你的姨妈巾**，我高兴极了，这说明你还没有怀孕，也就是说，你还没有男朋友。我把姨妈巾拿回去**泡茶喝**，因为里面有**你的味道**。',
'小时候抓周抓了个方向盘，爸妈都以为我长大了会当赛车手，最差也是个司机，没想到我长大了当了你的备胎。',
'经过两个多月疫情终于快结束了，我给你发消息显示被拉黑了，你还是这么贴心，怕疫情通过网络传播给我，原来你一直在默默保护着我。',
'女神发朋友圈发了一款新手机，我立马用我捡瓶子赚来的钱买的手机下了订单，看见女神朋友圈发了一条，**是哪个傻逼给我订的手机货到付款**，我开心的笑了，女神为我发朋友圈了，我放下手中的空瓶，默默的发誓我要舔她一辈子',
'她从来不说想我，聊天记录搜索了一下“想你”两个字全都是：“**那波你怎么不上啊，你在想你妈呢。**”',
'她好像从来没有主动说过爱我，我搜索了一下关键字“爱”。在我们的聊天记录里，她只说过一次：**你爱怎么想就怎么想**',
'蒋介石因为宋美龄的一句喜欢梧桐，他便种满了整个南京。而我因为你的一句不喜欢小偷，我便放过了整个上海的电动车。',
'春天的阳光穿过树叶的缝隙，我知道那是太阳经过小孔成像到在我身上的影。我抬头望去只觉一阵恍惚，看见了黑色长发的你。我知道那是你穿过我的心留下的影，但我却不知道这是什么成像原理。',
'我身体很好抗得了米袋子、抗得了煤气罐，却扛不住想你',
'天一亮我就给你发消息，你说你“**嗓子痛**”。我一下子就着急了，我很关心的说道：“买药没有、是衣服穿太薄了吗？这几天别吃辛辣食物，零食也别吃了，多喝点热水，早晚天气比较冷，衣服一定要注意，早上可以喝一杯热牛奶，换季的时候是容易感冒，是我没有叮嘱好你对不起。”，你说“**不是，是昨晚给别人口了。**” 我心想原来不是感冒啊吓我一跳。',
'今天他终于约我出去吃饭了，好开心，到了火锅店我发现，他正和另一个女孩子坐一起，他想得真周到，叫上其他人不会容易冷场，我走过去发现他们好像已经吃完了，他说**你别吃了去买一下单**，他真贴心，知道我最近减肥不能吃太多。我更爱他了！',
'我的嘴真笨，跟别人能说出花，嘴巴会像开过光，唯独跟你，怎么说都不太对。每天都要看很多遍微博，你稳居我微博经常访问第一的宝座，有什么好玩的都想分享给你只为逗你一笑。你的抑郁你的不快我都看在眼里急在心头，我想默默陪着你让你开心。天快亮了，又一包烟抽完。你是我最孤独的心事，能不能偶尔低下头看看我。',
'今天一早起来，想给你发信息，跟你分享了今天的早餐、今天的穿搭、还有今天的好心情，发了很多条信息给你，你回了我一个“滚”字 我在想，你一定是希望我们的感情像长江一样“**不尽长江滚滚来**”。',
'今天考了一天的试，很疲惫但是看见你的朋友圈，原来你和男朋友去吃了肯德基，真想和你一起去吃一次。',
'今天外面的天气不是很好，甚至说非常的差，我给你打了一个电话，你没有接，我再打，你还是没有接，我想你应该在睡觉吧。于是我带上口罩跑到你家门口，刚要敲门，一个男人打开了你家的房门。',
'时隔30个小时，你终于发了信息给我，你说“宝贝，我想你了。”，我很开心，我终于以为我的舔狗日子到了，可没想到信息发出来两秒都没有，你就撤回了，你说发错了，当我说准备要回没关系的时候，我看见了红色的感叹号。',
'我最近越来越期待夜晚了，因为白天都没什么机会和你说话，只能憋到晚上和你说句晚安。',
'今天问你中午打算吃什么，你说**吃NM**，我仔细思考了一下，NM是柠檬的缩写，你是因为我昨天在你闺蜜的评论下留言而感到酸，吃我醋了对吧？小傻瓜，我只爱你一个呀！',
'别的妹妹叫你打游戏，你让人家语音给你发了句哥哥，你就陪她打一天。我叫你打游戏，你回了我一句 70/h。',
'你老是以为我有很大的鱼塘，聊不完的小哥哥，其实一整天我除了刷朋友圈，就是看着你的对话框发呆，什么时候，你也能对我这个小笨蛋心动一回？',
'今天在微博发现了好好笑的事情，想分享给你，可是当我发给你的时候迎接我的只有一个大大的红色感叹号，对啊，**今天是你把我删了的第九十六天**呀。真的太真实了吧，我翻看了一下我们的聊天记录，喜欢和爱你都没有给我说过，我知道我不好看还有斑但是我真的喜欢你啊，太心酸了。',
'我今天送了你一支口红，你拿到之后很开心，在他的嘴巴上亲了一下，或许他送你口红的时候，你也会在我的嘴巴上亲一下吧。',
'你说憧憬过以后的生活，可你也没有说，憧憬过离开我的生活',
'她好像从来没有主动说过爱我，我搜索了一下关键字“爱”。在我们的聊天记录里，她只说过一次：**爱奇艺会员借我一下。**',
'今天和他玩吃鸡，他要去捡空投，载上其他两个人就走了，我在后面使劲地追，他好像是故意不让我上车，他知道我的技术，怕我一起去被打死，真是太懂我了，我更爱他了。',
'你跟他已经啪完了吧应该很累吧，我给你煮了粥送到你那里，你待会喝好补充体力。昨天晚上我听到你前半夜叫的声音很大，后半夜应该是有点哑了，我跑了好远给你买了润喉糖，下次让他温柔一点好吗？好好照顾自己不要，让他老抢你的被子，记得戴套。我永远都爱你！',
'不能删除微信，即使不聊天、即使你只会给我发表情包，因为删了就连聊天的机会都没有了。',
'我骗了我爸妈，买了张机票去见你，在你的城市我被骗了8000块钱，独自一人吃泡面时**看到你在街边和一个大叔热吻了5分钟**，在那5分钟里，我很想家，很想爸妈。',
'今天，你把我删了，说玩玩我罢了，还说了，对不起把我删了，你人真好，犯了错还会和我道歉啊，我真的是越来越喜欢你了。',
'今天我什么也没有得手，我有点心累，回家时路过一条街，街上的小姐姐热情的拉着我的手对我说“小哥哥快来玩啊~”我本想拒绝，可是我还是放弃了抵抗，因为想到你曾说过：**太早回家的男人没前途。**',
'今天你终于都我说了“喜欢”这个词，我等了好久了。你对我说：“**我喜欢的人不是你**”。我知道你是在考验我，宝贝我会一直等你的。',
'今天女神发朋友圈了，我保存了她的照片，想要当壁纸使用，因为我想离她更近一点。结果她说“**滚远点！**”然后就把我拉黑了。她好贴心，好善良，我真的好感动！疫情期间她怕我被感染，才让我离她远一点，处处为我着想，我真是越来越爱她了！',
'你已经十三个小时两分钟57秒没有回我消息了，一支晨光的水性笔可以把你的名字写两千四百三十五遍，这是我等你回消息时候发现的。',
'我好像从来没听过她对我说“爱你”，今天我搜了一下聊天记录，她只说过一次“**我在做爱你等一下**”。',
'今天没有巡逻，在小区里看漂亮的女孩子们戴着口罩去上班。向她们打招呼，她们却不理我，可能因为我只是个保安，保安亭没有暖气，值班一夜的我精疲力尽，只有想起你才会让我有一丝温暖，**想做你的保安，保你一生平安!**',
'我一直在舔一个女神，天天找她聊天，但是她总对我爱答不理的，后来我找她的次数便少了，有一天，她突然找我，问我为什么不舔她了，我说，老子消息太多回不过来了，**舔一个我是舔狗，舔一群我就是海王钓鱼。**',
'每次给你语音，都只有拒绝。然后你只会回复一个问号。我只好牵强的回复一句“**你挂我电话我就舒服**”',
'我们通过网上认识的，给你送了LV、YSL等你都没有拒绝，当看到你朋友圈说想出来走走的时候，我立马给你私信小窗口，你回了一句**见你妈**，我就知道你还是想跟我过日子的',
'我拥抱着残留你气息的空气，假装你还在，不曾离去。',
'你说你要一个人搬家好累的，我晚饭都没吃就跨了半个城市去帮你，没有电梯三楼我爬了十几趟没觉得累，都搬完了你说你该叫你哥一起帮忙的，于是你打电话给你哥要他过来，我知道你是在防备我，我很高兴你有保护自己的意识。我看了看没什么需要我帮忙的了，我说那挺晚了我就回去了，你嗯了一声，不曾说句再见，甚至没有回头看我一眼。转身往回走的瞬间我觉得我累的像条狗。',
'你说你的床太小，没关系啊，我们三个一起睡啊。',
'他给每个人都发了节日快乐，而我的却打了几个句号，我开心极了',
'听说猪肉涨价了，吓的我赶紧买了半条腿过去。你看着我手里一边拎着的猪腿子，一边红出血的玫瑰花，噗嗤一笑，说：“情人节还有礼物啊”，说着就让我把猪腿放到你的雅迪后座后就让我回去了，剩下我一人拿着花在风中凌乱。午后，你拿着不知谁的花和旁边猪腿发朋友圈，说男人都是大猪蹄子。',
'我想你一定很忙，所以你只要看前三个字就好了。',
'我跪着看你特别美。',
'今天和女神聊天一整天，她一直**回复我“嗯”“哦”**之类的，我就知道她一定是**在意我**的，不然怎么能回复我消息呢？你说以后我们结婚去哪里度假好呢？',
'今天又听到你的声音了，你虽然是在网课上和别人做爱忘关麦了，但我只想让他对你下手轻点，让你不那么痛苦的大叫，让他心疼你一点，别在你来姨妈的时候和你做爱，还有**要记得带好套哦**，爱你！',
'今天又看在你在和别人打情骂俏，明明是我先来的，可为什么会变成这样呢，可能还是我的爱不够吧，拿工资给你买了switch和动森，**希望你能跟他玩的开心。**',
'你跟他打完游戏了吧，也不知道他有没有在游戏里凶你。如果你不高兴了一定要告诉我哦，我会一直陪伴你的。今天队长看我表现好，奖了我一百块钱奖金，我现在就给你打过去，给你买小乔的皮肤，别人有的你也会有。',
'今天麦当劳桶半价，我赶紧买一份想要送给你，想告诉你**桶有半价，爱你无价。**可是我好笨，爱你说不出来 ，桶也保护不好。我委屈，我想你。',
'兄弟，我帮你刷本，你多去陪陪她。',
'你说你喜欢冰淇淋，我可以给你买，你说你喜欢AJ1，我可以给你买，你说你喜欢iPhone11，我可以给你买，可是为什么就是**买不了你对我的喜欢。**',
'天上的月亮，亮也没用，没用也亮；我喜欢你，喜欢也没用，没用也喜欢。',
'昨天打电话，你说了脚痛，叫的很痛苦。今天刚发了工资，我就赶紧给你转钱过去，想着让你好好的治疗。没想到晚上你就给我发语音了“啊啊啊，谢谢你啊！”，跟昨天一样还是叫的那么痛苦，那一刻我高兴的哭了，应该是理疗有效果了。',
'夜里，给你打电话，你**喘着气说在和男性朋友在跑步**，说希望我也多运动运动脖子。我很感动，你没有因为我没有脖子而嫌弃我，只是我不明白你为什么带着哭声和我说这些。',
'上周我给你发了520的红包你收到了什么都没说，今天你给我分享了lv包包的淘宝链接说你看上了好久。我用最后一点花呗额度帮你拍下了这个包包，你一定是想让我成为在你背后默默为你撑腰保驾护航的男人吧，想到这里，就有了还下个月花呗的动力了。',
'今天早上我来到你楼下，给你打了电话，问你在不在家，你说了一句“不在”便挂断了，随即便拉上了窗帘。离你更近的时候我能呼吸到你呼吸过的香甜空气也是一种不可多得的幸福了。',
'今天你又来我们班看美女了，路过的时候瞥了一眼坐在第一排的我，我就知道你心里还是有我的。啊！真是美好的一天！',
'吃过晚餐了，掏出手机，他们都说今天是白色情人节。我点开与你的对话框，发了一句：“在吗？”过了半个小时你也没有回复我，我又发了一条：“不在也行。”我委屈，我想你。over',
'和平精英里新出的角色和你很像你，我从早打到晚只是为了能在游戏里和你相遇，然后死在你的枪下。',
'朋友喊我去玩游戏，我拒绝了，因为我在等你。我坐在电脑前等你上线，你说“滚”。我很伤心，翻开了你的战绩-10连跪。原来你为了我不掉段才不和我一起玩的，果然你是爱我的。',
'和平精英里新出的角色和你很像你，我从早打到晚只是为了能在游戏里和你相遇，然后**死在你的枪下**。',
'看到你今天修改了微信号，我也想跟着你改，实在想不出要改成什么，我把微信号改成你之前的微信号，就在刚刚有个人加了我微信，第一句话是：熟人介绍，包夜多少',
'天空的乌云好像也有心事一样，那个女孩还是没有回复我一句话，可能她爱我不好意思说出口吧！',
'今天看到你发朋友圈说你分手了，我很开心，因为你说我是63号，今天开始我就是62号了，距离和你在一起又进一步了。',
'今天下班过后去扛水泥，想挣钱给你买个包，刚刚休息时候工头给我递了一支烟，说：“力气挺大啊，挺能扛啊。”我突然就哭了，我扛多少水泥都不是问题，就是**扛不住想你**。',
'你说最近吃够外卖了，我就买了本菜谱，在家自己学着做了一个月，终于做出了一道让周围朋友都说好吃的菜。我给你送上门，你对我说了谢谢。后来他说你根本没有吃我做的菜，都喂了家里的泰迪咪咪。但我依然很满足，因为感觉能跟你共同喂养你的爱宠了，我还会继续给你做饭的。',
'我知道你不会来到这个网站，但我还是想对你说：“我喜欢你，邱。”',
'能不能先别嫁他，彩票我每天都在坚持买……',
'轻点，她怕疼。',
'你扇了我一巴掌，我感受到你的手冰冰凉，给你买了暖手袋，你说我脑子进水了。我百思终得其解，你有2只手，我怎么可以只买一个暖水袋。于是我又买了一个，准备给你惊喜。对不起，是我粗心大意了！我会永远爱你！',
'你说你王者荣耀要买后裔的皮肤，我赶紧用花呗买给你。可能是你太不好意思了，你马上就把我拉黑了。不过我室友说你用这个皮肤和他玩了一个晚上，还问他要不要去你家玩这个会射日的英雄。我就知道你真的喜欢我给你的礼物。',
'半夜你说给你买两盒冈本送到你家，我知道我的机会来了。你拿到冈本立马让我走，说我就是比饿了么快。我边走边哭，因为终于有机会证明我是跑得最快的那条狗了。',
'你这个人不太会撒娇，说不太出这种以呐结尾的句子。我在我们的聊天记录中搜索了关键字“呐”，你经常和我说：我给你妈吹唢呐！',
'问你在干嘛，你问我能不能别烦你。居然询问我的意见，态度真好，真喜欢你！',
'孩子是不是我的无所谓，你生孩子都那么疼了。',
'今天和女神去爬山，她的脚崴了，蹲在地上小声的哭，我过去搂着她说：“怎么回事，没吃饭么？哭都哭不出来？”',
'给自己花钱也许会心疼，但给喜欢的人花钱却是快乐的，而且这种快乐没有人可以剥夺，没有人可以从中作梗，它是属于我一个人的幸福，永远。',
'今天加了女神的微信，我说晚上请她看电影，她说看你妈。真好，那么快就想着见家长了。',
'你说月亮很美，我说不如你美，你说我不要脸，我哭了！',
'我认识你很久了，我们的距离一直都没有变。那天我生日拒绝了所有朋友想要单独请你吃饭，你说会给我一个大大的惊喜。你把他带来了，你跟我说你们也就认识两个星期，但他现在是你的对象了。我也给你准备了个惊喜，waiter推上来玫瑰和蛋糕的时候你以为是他给你准备的，那一刻我觉得自己好多余，但他不一定能在你身边很久，也许我才是最久的，只是距离也是最远的。',
'和他退房了吗？可以回我消息了吗？',
'昨晚和你缠绵的时候特别开心，应该说每晚受你邀约去你家我都很开心，第二天依旧按照约定九点前我就离开，今天意外收到了你的信息，你嘱咐我说：丽丽注意安全，下回见。我更加开心了，尽管我叫菲菲。',
'再也不做舔狗了，从今往后你走你的独木桥，我在桥下为你撑着。走的时候小心点别掉下来了，我会心疼的。',
'昨天，我给你发了一句“早安”，你没有回我，我还能等，我可以等很久，直到你回过头来看我哪怕一眼。',
'那天你生日没叫我去，我还是用手机给你发了520的红包，给你买了礼物，没想到你居然回了消息：再见。所以你还是想见我的对吧！我也期待和你见面呢！',
'去年让你10万嫁给我，你父母非要20万。今天巷子里遇见你，你只要150块，我还能说什么呢？祝你生意兴隆！',
'和你谈恋爱真的好开心，你天天都要吃外卖，没事就要喝奶茶；谈上没有一个礼拜就过生日，认识我手机就坏；游戏打得一般也要买皮肤还要买传说限定星传说；见了一次面就说要为了我打掉孩子。我血都要卖干了，但是我愿意，说明你衣食住行吃喝玩乐都离不开我，你依赖我一辈子。',
'每天点开你的QQ资料卡，看着你的头像。啊，换头像了？是不是情侣头像啊？我心里一惊，心跳加速，赶紧上网搜，好像不是情侣头像，那我是不是还有机会啊……',
'听说你和你的女友分手了，我以为机会来了。没想到你又有了新的女友。',
'给你发了好几百条信息了，你也不回，我都想不理你了，可是你突然回了一个“嗯”，我的快乐又回来了。哈哈哈哈哈哈哈哈哈',
'你知道吗？流星雨划过天空的时候，我许了一个你愿望成真的愿望。',
'今天口腔溃疡了，不太想舔你了，但是我爱你，含了一片西瓜霜后，又给你发了99条信息。',
'今天又被女神感动到了，陪她去打胎，她躺在病床上虚弱的对我说“**亲爱的，不是你的孩子我不要。**”',
'给她发信息，就问了一句你好，她就让我哪凉快去哪呆着。果然她还是爱我的，怕我在这么热的天，热着了，你放心我这一生只会爱你一个人的。',
'你说别缠着你了并给我看了你女朋友的照片，啊~不愧是你！女朋友都这么漂亮~',
'我对你能接受的最大程度是：有一天我陪你打胎，然后和你永远在一起！',
'女神每次买包包首饰总要跟我借点钱，我知道这是依赖我，是爱我的表现。前几天我看见她在空间里发了她50多岁有钱男友的照片，我太感动了，她一定是怕我的负担太重，所以自己想办法分担。我现在更爱她了❤',
'听着窗外稀稀拉拉的雨声，我忽然想到你对我说的话。对啊，生孩子本来就够痛苦了，还管是谁的干嘛呢？',
'对不起，我接受不了网恋，虚无缥缈的东西没办法给我带来安全感，猜忌和怀疑是深夜流泪的根源，我要稳稳的幸福，一伸手就能触碰的那种 ，但看到你这张照片我就知道：我能接受。',
'没关系的，我可以用好友申请每天向你问好。',
'“她根本就不喜欢你，她喜欢的是你的钱！”，“胡说！那别人的钱她怎么不喜欢就喜欢我的钱？还不是因为喜欢我！”',
'你今天突然给我发消息说你搬家让我来帮你，我立马跑来，帮你跑上跑下，后来你说，辛亏是你，不然我男朋友会累坏的',
'昨天早上给你问好，你说今天七夕想给男朋友惊喜，我给你转了520，你开了间水床房，说不舍得男朋友花钱！',
'没事，你有对象不重要，你可以偶尔回一下我的信息好吗，一天一条也行，让我知道你还在。',
'昨天晚上我给你发短信，你回了一句“等等”，然后我就双眼盯着屏幕等到天亮你也再没有回信息，我想一定是你担心我睡眠不足，所以没有回我。',
'多希望有一天你对我说：“宝，早安！”，而不是：“早，保安！”',
'留下的TT，你和现在的他继续用吧！',
'我不敢有什么奢望，只希望亲他的时候要记得涂上我送你的唇膏.......',
'我记得你唯一和我说过有关于我的话题于评价是：你这个人好烦啊，给我闭嘴，滚远点好吗？我真的好感动啊，你知道我最近嗓子不好，要我少说话；也知道我的炎症会传染，所以才让我走开；真是太关心我了，我好爱你呀！！！',
'上课用镜子偷偷在看你，想着光的反射，你看我这边你就可以看到我，一节课硬是没看到你在看我。',
'以后你的屎就拉在我嘴里吧！',
'他说：“你不要再打电话了，请你不要做第一个被我拉黑的女生”',
'我说我想要甜甜的恋爱，他说馊的就有，我说馊的我也要！',
'给你买的iPhone11刚分期完，iPhone12就出了，这个时候你来找我复合，我就知道你还是爱我的，谢谢你苹果公司！',
'你发朋友圈说你想吃铜锣烧，半夜我起来给你买，送到你家楼下我跟你打电话，你气喘吁吁的跟我说你在跑步，可我看到你房间的灯还亮着，我懂了，可能是你不想麻烦我吧！'
];

function randomSoul(){
	content = soul[Math.floor(Math.random() * soul.length)];
	document.getElementsByTagName('article')[0].innerHTML = content.replace(/\*\*(.*?)\*\*/g,'<mark>$1</mark>');
	document.getElementsByTagName('section')[0].className = 'border-' + randomNumBoth(1,6);
}

